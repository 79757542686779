import Vue from 'vue';
import {store} from '@/shared/store'
import TRANSLATION_GERMAN from "@/shared/translations/translations.de.json";
import {Translation} from "@/shared/translations/Translation.plugin";
import {CATEGORY_GOOGLE_MAPS, TagManager} from '../js/modules/TagManager';

Vue.config.productionTip = true
Vue.config.devtools = true;
Vue.use(Translation, TRANSLATION_GERMAN);

if (document.querySelector('.hero-slider')) {
    import(/* webpackChunkName: "hero-slider" */ '@/HeroSlider/main')
        .then(Module => Module.initialize(Vue));
}

if (document.querySelector('.offer-with-price')) {
    import(/* webpackChunkName: "offer-with-price" */ '@/OfferWithPrice/main')
        .then(Module => Module.initialize(Vue));
}

if (document.querySelector('.offer-with-price-small')) {
    import(/* webpackChunkName: "offer-with-price-small" */ '@/OfferWithPriceSmall/main')
        .then(Module => Module.initialize(Vue));
}

if (document.querySelectorAll('.nav__location').length > 0) {
    import(/* webpackChunkName: "nav-location" */ '@/NavLocation/main')
        .then(Module => Module.initialize(Vue, store));
}

if (document.querySelectorAll('.footer__contact').length > 0) {
    import(/* webpackChunkName: "footer-location" */ '@/FooterLocation/main')
        .then(Module => Module.initialize(Vue, store));
}

if (document.querySelectorAll('.pdf-catalog-list').length > 0) {
    import(/* webpackChunkName: "pdfviewer" */ '../js/modules/pdfviewer/pdfviewer.min')
        .catch(() => console.error('pdfviewer could not be loaded'));
    import(/* webpackChunkName: "pdf-catalog-list" */ '@/PDFCataloqueList/main')
        .then(Module => Module.initialize(Vue, store));
}

function startMarketSearch() {
    import(/* webpackChunkName: "market-search" */ '@/MarketSearch/app')
        .then(Module => Module.initialize());
}

if (document.querySelectorAll('.marketsearch')) {

    const tagManager = new TagManager();
    tagManager
        .addEventListener('accepted', (categoryId: string) => {
            if (categoryId === CATEGORY_GOOGLE_MAPS) {
                startMarketSearch();
            }
        })
        .addEventListener('declined', () => location.reload());

    if (tagManager.isCategoryAccepted(CATEGORY_GOOGLE_MAPS)) {
        startMarketSearch();
    }
}


function startHeroMap() {
    import(/* webpackChunkName: "market-search" */ '@/HeroMap/app')
        .then(Module => Module.initialize());
}

if (document.querySelectorAll('.heromap')) {

    const tagManager = new TagManager();
    tagManager
        .addEventListener('accepted', (categoryId: string) => {
            if (categoryId === CATEGORY_GOOGLE_MAPS) {
                startHeroMap();
            }
        })
        .addEventListener('declined', () => location.reload());

    if (tagManager.isCategoryAccepted(CATEGORY_GOOGLE_MAPS)) {
        startHeroMap();
    }
}

if (document.querySelectorAll('.store-selection-field').length > 0) {
    import(/* webpackChunkName: "store-selection-field" */ '@/StoreSelectionField/app')
    .then(Module => Module.initialize(Vue));
}

if (document.querySelectorAll('.offer-selection-field').length > 0) {
    import(/* webpackChunkName: "offer-selection-field" */ '@/OfferSelectionField/app')
    .then(Module => Module.initialize(Vue));
}
