import Vue from 'vue'
import Vuex, {ActionContext} from 'vuex'
import {LocationInterface} from "@/shared/interfaces/LocationInterface";

Vue.use(Vuex)


export interface StoreInterface {
  userLocation: LocationInterface | null
  showLocation: boolean;
}
type StoreActionContext = ActionContext<StoreInterface, {}>

export const store =new Vuex.Store({
  state: {
    userLocation: null,
    showLocation: false
  },
  mutations: {
    SET_USER_LOCATION: (state: StoreInterface, payload: LocationInterface) =>  {
      state.userLocation = payload;
    },
    SET_SHOW_LOCATION(state: StoreInterface, payload: boolean) {
      state.showLocation = payload;
    }
  },
  actions: {
    setUserLocation(context: StoreActionContext, userLocation: LocationInterface | null) {
      context.commit('SET_USER_LOCATION', userLocation);
      context.commit('SET_SHOW_LOCATION', true);
    },
    setShowLocation(context: StoreActionContext, payload: boolean) {
      context.commit('SET_SHOW_LOCATION', payload);
    }
  },
  getters: {
    userLocation(state: StoreInterface) {
      return state.userLocation;
    },
    showLocation(state: StoreInterface) {
      return state.showLocation;
    },
  },
  modules: {
  }
})
