import _Vue from "vue";

export type TranslationDefinitionType = { [key: string]: string };

export function Translation(Vue: typeof _Vue, translations: TranslationDefinitionType): void {
    Vue.filter(
        'translate',
        (value: string) => translations[value] || `[MISSING TRANSLATION: ${value}]`
    );
}
