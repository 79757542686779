import 'core-js';
import 'picturefill';
// import '../vue/main'
import {UserLocation} from "../vue/shared/services/UserLocation";
//import {AnchorLinkHandler} from "./modules/anchorHandling";
//import {LMIVLogoLink} from "./modules/LMIVLogoLink";


if (document.querySelector('[data-tracking-component], [data-tracking]')) {
    import(/* webpackChunkName: "matomo-event-tracking" */ './modules/matomoEventTracking')
        .then(module => module.MatomoEventTracking.init())
        .catch(() => console.error('matomo-event-tracking could not be loaded'));
}

if (document.querySelector('.form-wrapper form')) {
    import(/* webpackChunkName: "recaptcha" */ './modules/formValidation')
        .then(module => module.FormValidation.init())
        .catch(() => console.error('form validation could not be loaded'));
}

if (document.querySelector('a[data-pdf-viewer]')) {
   import(/* webpackChunkName: "pdfviewer" */ './modules/pdfviewer/pdfviewer.min')
        .catch(() => console.error('pdfviewer could not be loaded'));
}

if (document.querySelector('[data-tagmanager]')) {
    import(/* webpackChunkName: "tag-manager" */ './modules/TagManagerButton')
        .then(module => module.TagManagerButton.init())
        .catch(() => console.error('tag-manager could not be loaded'));
}

if (document.getElementById('store-finder-widget')) {
    import(/* webpackChunkName: "uberall" */ './modules/Uberall')
        .then(module => module.Uberall.init())
        .catch(() => console.error('uberall could not be loaded'));
}

if (document.querySelector('.gallery')) {
    import(/* webpackChunkName: "gallery" */ './modules/gallery/gallery')
        .then(module => module.GalleryInit())
        .catch(() => console.error('gallery could not be loaded'));
}

if (document.querySelector('.footer__link-box')) {
    import(/* webpackChunkName: "footer" */ './modules/footer')
        .then(module => {
            const footer = new module.Footer();
            footer.init();
        })
        .catch(() => console.error('footer could not be loaded'));
}

if (document.querySelector('.js-slider')) {
    import(/* webpackChunkName: "swiper" */ './modules/default-swiper')
        .then(module => {
            const swiperDefault = new module.SwiperDefault();
            swiperDefault.init();
        })
        .catch(() => console.error('swiper could not be loaded'));
}

if (document.querySelector('.js-nav')) {
    import(/* webpackChunkName: "navigation" */ './modules/navigaton')
        .then(module => {
            const navigation = new module.Navigation()
            navigation.init();
        })
        .catch(() => console.error('navigation could not be loaded'));
}

if (document.querySelector('.js-accordion')) {
    import(/* webpackChunkName: "accordion" */ './modules/accordion')
        .then(module => {
            const accordeon = new module.Accordeon();
            accordeon.init();
        })
        .catch(() => console.error('accordion could not be loaded'));
}

if (document.querySelector('.video-teaser[data-youtube-video-id]')) {
    import(/* webpackChunkName: "video-teaser" */ './modules/video-teaser/VideoTeaser')
        .then(module => module.VideoTeaser.init())
        .catch(() => console.error('video-teaser could not be loaded'));
}

if (document.querySelector('.js-pop-up')) {
    import(/* webpackChunkName: "pop-up" */ './modules/popUp')
        .then(module => module.PopUp.init())
        .catch(() => console.error('popUp could not be loaded'));
}

if (document.querySelector('.form')) {
    import(/* webpackChunkName: "magnolia-formstyler" */ './modules/Magnoliaformstyler')
        .then(module => {
            const formStyler = new module.Magnoliaformstyler();
            formStyler.init();
        })
        .catch(() => console.error('magnolia-formstyler could not be loaded'));
}

if (document.querySelector('.rtf__pdf-download')) {
    import(/* webpackChunkName: "ck-asset-dam-link-editor" */ './modules/ckAssetDamlinkEditor')
        .then(module => {
            const ckAssetDamlinkEditor = new module.CkAssetDamlinkEditor();
            ckAssetDamlinkEditor.init();
        })
        .catch(() => console.error('ck-asset-dam-link-editor could not be loaded'));
}

if (document.querySelector('[data-link]')) {
    import(/* webpackChunkName: "data-link" */ './modules/DataLink')
        .catch(() => console.error('data-link could not be loaded'));
}

if(document.querySelector('.location-detail')) {
    import(/* webpackChunkName: "location-detail" */ './modules/location-detail/LocationDetail')
        .then(module => module.init())
        //.catch(() => console.error('location-detail could not be loaded'));
}

if (document.querySelector('.js-autoSizer')) {
    import(/* webpackChunkName: "autoSizer" */ './modules/AutoSizer')
        .then(module => module.AutoSizer.init())
        .catch(() => console.error('AutoSizer could not be loaded'));
}

if (document.getElementById('branch')) {
    import(/* webpackChunkName: "customer-data" */'./modules/customer-data/customerData')
        .then(module => module.CustomerData.init())
        .catch(() => console.error('customer-data could not be loaded'));
}

if (document.querySelector('.lmiv-logo-link')) {
    import(/* webpackChunkName: "customer-data" */ './modules/LMIVLogoLink')
        .then(module => module.LMIVLogoLink.init())
        .catch(() => console.error('customer-data could not be loaded'));
}
if (document.querySelector('.js-teaser-item-search')) {
    import(/* webpackChunkName: "teaser-item-search" */ './modules/teaserItemSearch')
        .then(module => module.TeaserItemSearch.init())
        .catch(() => console.error('teaser-item-search could not be loaded'));
}
if (document.querySelector('.js-small-teaser-swiper')) {
    import(/* webpackChunkName: "small-teaser-swiper" */ './modules/smallTeaserSwiper')
        .then(module => module.SmallTeaserSwiper.init())
        .catch(() => console.error('small-teaser-swiper could not be loaded'));
}
if (document.querySelector('.lazy-background')) {
    import(/* webpackChunkName: "lazy-background" */ './modules/lazyBackground')
        .then(module => module.LazyBackground.init())
        .catch(() => console.error('lazy-background could not be loaded'));
}

if(UserLocation.has() && document.getElementsByTagName('a').length > 0) {
    import(/* webpackChunkName: "offer-link" */ './modules/offer-link-manager')
        .then(module => module.init(UserLocation.get()))
        .catch(() => console.error('offer-link could not be loaded'));
}

if(document.body.querySelector('a[href*="#"]') || location.hash !== '') {
    import(/* webpackChunkName: "anchor-handling" */ './modules/anchorHandling')
        .then(module => module.AnchorLinkHandler.init())
        .catch((e) => console.error('anchor-handling could not be loaded', e));
}
