export const CATEGORY_YOUTUBE = '10';
export const CATEGORY_GOOGLE_MAPS = '8';
export const CATEGORY_GOOGLE_DOUBLECLICK = '7';
export const CATEGORY_GOOGLE_RECAPTURE = '9';
export const CATEGORY_MATOMO = '14';

export class TagManager {
    constructor() {
        window.addEventListener('load', () => this.init());
        this.categories = [
            CATEGORY_YOUTUBE,
            CATEGORY_GOOGLE_MAPS,
            CATEGORY_GOOGLE_DOUBLECLICK,
            CATEGORY_GOOGLE_RECAPTURE,
            CATEGORY_MATOMO
        ]
        this.categoryStatus = [];
        this.eventListeners = {};
    }

    open() {
        if(tC && tC.privacy) {
            tC.privacy.showPrivacyCenter();
        }
    }

    init() {
        if (typeof tC !== 'undefined' && typeof tC.privacy !== 'undefined') {
            tC.eventTarget.addEventListener('consent-update', _ => this.onUpdate());
            this.onUpdate(true);
        }
    }

    getOptinCategories() {
        if (typeof tC !== 'undefined' && typeof tC.privacy !== 'undefined') {
            return tC.privacy.getOptinCategories();
        }
        return [];
    }

    isCategoryAccepted(categoryId) {
        const cookieSettings = document.cookie.match(/TC_PRIVACY_CENTER=([0-9A-Fa-f%]+);?/)
        if(cookieSettings) {
            const optinCategories = decodeURIComponent(cookieSettings[1]).split(',');
            return optinCategories.indexOf(categoryId) !== -1;
        }
        return false;
    }

    isCategoryChanged(categoryId) {
        const oldStatus = this.categoryStatus.indexOf(categoryId) !== -1;
        const newStatus = this.isCategoryAccepted(categoryId);

        return oldStatus !== newStatus;
    }

    onUpdate(init = false) {
        this.categories.forEach(categoryId => {
            if(this.isCategoryChanged(categoryId)) {
                const accepted = this.isCategoryAccepted(categoryId);
                this.dispatchEvent('changed', {categoryId, accepted});
                if(!init) {
                    this.dispatchEvent('changed-by-user', {categoryId, accepted});
                }
                if (accepted) {
                    this.dispatchEvent('accepted', categoryId);
                } else {
                    this.dispatchEvent('declined', categoryId);
                }
            }
        });
        this.categoryStatus = this.getOptinCategories();
    }

    addEventListener(eventName, callback) {
        if(!this.eventListeners[eventName]) {
            this.eventListeners[eventName] = [];
        }
        this.eventListeners[eventName].push(callback);
        return this;
    }

    dispatchEvent(eventName, data = null) {
        if(this.eventListeners[eventName]) {
            this.eventListeners[eventName].forEach(callback => callback(data));
        }
    }
}
