const LOCAL_STORAGE_ITEM = 'efs-location';

export class UserLocation {
    public static has(): boolean {
        return !!localStorage.getItem(LOCAL_STORAGE_ITEM);
    }

    public static get(): string | null {
        if (!this.has()) return null;
        return localStorage.getItem(LOCAL_STORAGE_ITEM);
    }

    public static set(identifier: string, city: string) {
        localStorage.setItem(LOCAL_STORAGE_ITEM, identifier);
        localStorage.setItem('efs-myMarket', city);
        window.dispatchEvent(
            new Event('FavoriteChanged')
        );
    }

    public static remove() {
        localStorage.removeItem(LOCAL_STORAGE_ITEM);
        localStorage.setItem('efs-myMarket', 'Mein Lieblingsmarkt');
        window.dispatchEvent(
            new Event('FavoriteChanged')
        );
    }
}
